import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '千语空间'
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/pay.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '千语空间'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/login.vue'),
    meta:{
      // 页面标题title
      title: '登录'
    }
  },
  {
    path: '/pclogin',
    name: 'pclogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/pclogin.vue'),
    meta:{
      // 页面标题title
      title: '千语空间'
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/personal.vue'),
    meta:{
      // 页面标题title
      title: '用户中心'
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/shop.vue'),
    meta:{
      // 页面标题title
      title: '商城'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/user.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '用户中心'
    }
  },

  {
    path: '/const',
    name: 'const',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/const.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '千语空间'
    }
  },
  // {
  //   path: '/ys',
  //   name: 'ys',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/home/ys.vue'),
  //   meta:{
  //     // 页面标题title
  //     // title: '千语空间'
  //     title: '千语空间'
  //   }
  // },
  {
    path: '/agreement/secrecy',
    name: 'ys',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/agreement/secrecy.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '千语空间'
    }
  },
 
  {
    path: '/cz',
    name: 'cz',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/cz.vue'),
    meta:{
      // 页面标题title
      // title: '千语空间'
      title: '千语空间'
    }
  },
  {
    path: '/app',
    name: 'app',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/app.vue'),
    meta:{
      // 页面标题title
      title: '千语空间'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/recharge.vue'),
    meta:{
      // 页面标题title
      title: '千语空间'
    }
  },
  {
    path: '/rechargePc',
    name: 'rechargePc',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/rechargePc.vue'),
    meta:{
      // 页面标题title
      title: '千语空间'
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
